<template>
  <App
    title="周例上下午"
    left-arrow
    class="week"
  >
  <weekSlider
      todayTxtColor='#DA3738'
      activeBgColor='#ffffff'
      activeTxtColor='#333'
      :showYear="true"
      :isAmPm="isAm"
      :disabled='disabledDay'
      :defaultDate="defaultDay"
      :currentTime.sync="currentTime"  
    >
    </weekSlider>
  </App>
</template>

<script>
import weekSlider from '_c/weekSlider'
import './index.less'
export default {
  name: 'weekDate',
  data() {
    return {
      loading: false,
      isAm: true,
      defaultDay: '2020-08-12',// 默认日期，不填是本日
      disabledDay: ['2020-08-09:am', '2020-08-11:pm', '2020-08-15:am'],// 不可用日期
      currentTime: '',
      weekReset: false
    }
  },
  components: {
    weekSlider: weekSlider.weekSlider
  },
  watch: {
    // 观察选中日期
    currentTime: {
        handler: function (newVal) {
          console.log(newVal)
        },
        immediately: true
      }
  },
  methods: {
    init (){
      // 加载状态结束
      this.loading = false;
      this.weekReset = true
    }
  },
  mounted () {
    this.weekReset = true
    
  }
}
</script>
