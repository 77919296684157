<template>
    <div>
        <div class="year_str" v-if="showYear">{{yearMonthStr}}</div>
        <div class="week-slider">
            <div
                class="sliders"
                ref="sliders"
                @touchstart="touchstartHandle"
                @touchmove="touchmoveHandle"
                @touchend="touchendHandle"
                :style="isAmPm ? 'height:140px' : 'height: 72px'"
                >
                <div v-for="(item, index) in dates" :key="item.date">
                    <div class="slider"
                        :style="getTransform(index)"
                        @webkit-transition-end="onTransitionEnd(index)"
                        @transitionend="onTransitionEnd(index)">
                        <div class="view">
                          <div class="am">
                            上午
                          </div>
                          <div class="pm">
                            下午
                          </div>
                        </div>
                        <div class="day" v-for="day in getDaies(item.date)" :key="day.date">
                            <div
                              :style="buildDateStyle(day.isToday, day.isDay)"
                            >
                                {{day.week}}<br><strong>{{day.date.split('-')[2]}}</strong>
                            </div>
                            <div v-if="isAmPm">
                              <Button type="default" :class="apm(day.date+':am', day)" :id="'d'+'_'+day.date+'_am'"  @click="selectDay('d'+'_'+day.date+'_am')" ></Button>
                              <Button type="default" :class="apm(day.date+':pm', day)" :id="'d'+'_'+day.date+'_pm'" @click="selectDay('d'+'_'+day.date+'_pm')" ></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable*/
import dayjs from 'dayjs'
export default {
    name: 'weekSlider',
    props: {
        defaultDate: {
            type: String,
            default: dayjs().format('YYYY-MM-DD')
        },
        showYear: {
            type: Boolean,
            default: false
        },
        isAmPm: {
            type: Boolean,
            default: false
        },
        activeBgColor: {
            type: String,
            default: 'rgba(182, 30, 40, 1)'
        },
        todayBgColor: {
            type: String
        },
        activeTxtColor: {
            type: String,
            default: 'rgba(255, 255, 255, 1)'
        },
        todayTxtColor: {
			    type: String
        },
        disabled: {
          type: Array,
          default: []
        },
        lang: {
          type: String,
          default: 'ch'
        },
        currentTime: {
          type: String,
          default: ''
        }
    },
    data () {
        return {
            dates: [],
            direction: null,
            activeIndex: 1,
            isAnimation: false,
            yearMonthStr: '',
            start: {
                x: null,
                y: null
            },
            end: {
                x: null,
                y: null
            },
            distan: {
                x: 0,
                y: 0
            },
            sliderWidth: 0,
            weekLanguages: {
              ch: ['日', '一', '二', '三', '四', '五', '六'],
              en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']
            }
        }
    },
    watch: {
        dates: {
            handler: function (newVal) {
                this.yearMonthStr = dayjs(newVal[1].date).format('YYYY-MM')
            },
            deep: true
        }
	  },
    computed: {
      todayStyle: function () {
        let vm = this
        return {
          color: vm.todayTxtColor ? vm.todayTxtColor : ''
        }
      }
    },
    mounted () {
        this.sliderWidth = this.$refs.sliders.offsetWidth
    },
    created () {
      let vm = this
      this.dates.push(
          {
              date: dayjs(vm.defaultDate).subtract(7, 'd').format('YYYY-MM-DD'),
          },
          {
              date: vm.defaultDate,
          },
          {
              date: dayjs(vm.defaultDate).add(7, 'd').format('YYYY-MM-DD'),
          }
      )
    },
    methods: {
        apm: function(time) {
          let classStr = 'apm '
          if (this.disabled.indexOf(time) != -1) {
            classStr = 'apm disabled '
          }
          return classStr
        },
        /*
        * 选择日期
        */
        selectDay (dayApm) {
          if (document.querySelector(`#${dayApm}`).className.indexOf('disable') != -1) return false
          document.querySelectorAll(`.apm`).forEach(e=> {
            e.className = e.className.replace('selected', '')
          })
          document.querySelector(`#${dayApm}`).className += 'selected'
          this.$emit('update:currentTime', dayApm.slice(2).replace('_', ':'))
        },
        /**
        *获取制定日期的当前周的日期数据
        */
        getDaies (date) {
            let vm = this,
                arr = []
            let weekOfDate = Number(dayjs(date).format('E'))
            let weeks = vm.weekLanguages[vm.lang]
            let today = dayjs()
            let defaultDay = dayjs(vm.defaultDate)
            if (weekOfDate === 7) {
                weekOfDate = 0
            }
            for (var i = 0; i < 7; i++) {
                let _theDate = dayjs(date).subtract(weekOfDate - i, 'd')
                arr.push({
                    date: _theDate.format('YYYY-MM-DD'),
                    week: weeks[i],
                    isToday: _theDate.format('YYYY-MM-DD') === today.format('YYYY-MM-DD'),
                    isDay: _theDate.format('E') === defaultDay.format('E')
                })
            }
            return arr
        },

        /**
        *根据索引计算出样式
        */
        getTransform (index) {
            let vm = this
            let style = {}
            if (index === vm.activeIndex) {
                style['transform'] = 'translateX('+ vm.distan.x +'px)'
            }
            if (index < vm.activeIndex) {
                style['transform'] = 'translateX(-100%)'
            }
            if (index > vm.activeIndex) {
                style['transform'] = 'translateX(100%)'
            }
            style['height'] = this.isAmPm ? '140px' : '72px'
            style['transition'] = vm.isAnimation ? 'transform .5s ease-out' : 'none'
            return style
        },



        /**
         * touchstart handle
         */
        touchstartHandle (event) {
            let vm = this,
                touch = event.touches[0]
            vm.start.x = touch.pageX
            vm.start.y = touch.pageY
        },

        /**
         * touchmove handle
         */
        touchmoveHandle (event) {
            let vm = this,
                touch = event.touches[0]
            vm.isAnimation = true
            vm.end.x = touch.pageX
            vm.end.y = touch.pageY
            vm.distan.x = vm.end.x - vm.start.x
            vm.distan.y = vm.end.y - vm.start.y
            // console.log(vm.end.x , vm.start.x)
            let dom = vm.distan.x < 0 ? this.$refs.sliders.children[2] : this.$refs.sliders.children[0]
            if (vm.distan.x < 0) {
              // dom.style['transform'] = 'translateX('+ (vm.sliderWidth + vm.distan.x) +'px)'
              dom.style['transform'] = 'translateX('+ (0) +'px)'
            }else {
              dom.style['transform'] = 'translateX('+ (0) +'px)'
              //  dom.style['transform'] = 'translateX('+ (-vm.sliderWidth + vm.distan.x) +'px)'
            }
        },

        /**
         * touchend handle
         */
        touchendHandle (event) {
            let vm = this,
                touch = event.changedTouches[0]
            vm.isAnimation = true
            vm.end.x = touch.pageX
            vm.end.y = touch.pageY
            vm.distan.x = vm.end.x - vm.start.x
            vm.distan.y = vm.end.y - vm.start.y

            vm.getTouchDirection(vm.distan.x, vm.distan.y)
            if (vm.direction === 'left') {
                vm.activeIndex = 2
            } else if (vm.direction === 'right') {
                vm.activeIndex = 0
            } else {
                for (var i = 0; i < this.$refs.sliders.children.length; i++) {
                    this.$refs.sliders.children[i].style['transform'] = 'translateX('+ (i*100 - 100) +'%)'
                }
            }
            vm.distan.x = 0
            vm.distan.y = 0
            vm.direction = null
        },

        onTransitionEnd (index) {
            let vm = this
            vm.isAnimation = false
            if (index === 1 && this.activeIndex === 2) {
                vm.dates.push({
                    date: dayjs(vm.dates[vm.activeIndex].date).add(7, 'd').format('YYYY-MM-DD')
                })
                vm.dates.shift()
                vm.activeIndex = 1
            }else if (index === 1 && this.activeIndex === 0) {
                vm.dates.unshift({
                    date: dayjs(vm.dates[vm.activeIndex].date).subtract(7, 'd').format('YYYY-MM-DD')
                })
                vm.dates.pop()
                vm.activeIndex = 1
            }
        },

        /**
         * getAngle 计算角度
         */
        getAngle (x, y) {
            return Math.atan2(y, x) * 180 / Math.PI;
        },

        /**
         * getTouchDirection 获取滑动方向
         */
        getTouchDirection (x, y) {
            let vm = this
            if (Math.abs(x) > 20) {
                let angle = vm.getAngle(x, y)
                if (angle >= -45 && angle <= 45) {//向右
                    vm.direction = 'right'
                } else if ((angle >= 135 && angle <= 180) || (angle >= -180 && angle < -135)) {//向左
                    vm.direction = 'left'
                }
            }
        },

        dayClickHandle (date) {
            this.$emit('dateClick', date)
            this.$emit('update:defaultDate', date)
		},

		/**
		 *生成日期样式
		 */
		buildDateStyle (isToday, isActive) {
			let vm = this
			let res = {}

			if (isToday) {
				res.color = vm.todayTxtColor || ''
				res.backgroundColor = vm.todayBgColor || ''
			}

			if (isActive) {
				res.color = vm.activeTxtColor || ''
				res.backgroundColor = vm.activeBgColor || ''
			}

			return res
		}
    }
}
</script>
<style lang="less" scoped>
.year_str{
    height: 72px; border-bottom: #ddd solid 1px; line-height: 72px; text-align: center;
}
.week-slider{
    width: 100%; height: auto; min-height: 72px;overflow: hidden; padding: 10px 0;
    .sliders{
        position: relative;
        .slider{
             width: 100%;  display: flex; position: absolute; top: 0; left: 0; overflow: hidden;
            .day{
                flex: 1;
                div{
                    height: 60px; width: 72px; padding: 6px 0; margin: auto; text-align: center; line-height: 36px; font-size: 12px; border-radius: 50%;
                    &.sameDay{
                        background-color: #999; color: #FFF;
                    }
                    strong{
                        font-size: 14px;
                    }
                    .apm{
                      border-radius: 5px;
                      width: 72px; 
                      height: 60px;
                      margin-top: 15px;
                      line-height: 60px;
                      color: #fff;
                      white-space: nowrap;
                      font-size: 10px !important;
                      background: rgba(240,245,255); 
                      border:0
                    }
                    .disabled{
                      background: #f0f2f6; 
                    }
                    .selected{
                      background: #DA3738; 
                    }
                    .actived{
                      border: 0;
                      color: #fff;
                    }
                }
            }
            .view{
              font-size: 10px;
              .am{
                margin-top: 90px;
                height: 60px; 
                width: 20px; 
                padding: 10px ; 
                line-height: 30px;
              }
              .pm{
                height: 60px; 
                width: 20px; 
                padding: 10px ; 
                line-height: 30px;
              }
            }
        }
    }
}
</style>
